<template>
    <div>
        <s-form class="row" @submit="onSubmit">
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="Name of Organization*" class="c-input" v-model="model.organization_name"
                                 :rules="{required:true}" name="Name of Organization"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="First Name*" class="c-input" v-model="model.first_name"
                                 :rules="{required:true}" name="First Name"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="Last Name" class="c-input" v-model="model.last_name"
                                 :rules="{required:false}" name="Last Name"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="Father/Husband Name" class="c-input" v-model="model.father_husband_name"
                                 :rules="{required:false}" name="Father/Husband Name"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input type="number" placeholder="Phone Number*"
                                 class="c-input c-input-number-modify-arrow-none"
                                 v-model="model.mobile_number" :disabled="true"
                                 :rules="{required:true, min:10, max:10}" name="Phone Number"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="PAN*" class="c-input c-input-number-modify-arrow-none"
                                 v-model="model.pan" name="Pan"
                                 :rules="{required:true, regex: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/}"/>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-textarea placeholder="Address" class="c-textarea" v-model="model.address"
                                    :rules="{required:false}" name="Address"></validated-textarea>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-vue-select :options="countryOptions" placeholder="Country*" @input="setState(model.country)"
                                      class="c-input c-input-select-b c-ajax-selected-white" v-model="model.country"
                                      :rules="{required:true}" name="Country"></validated-vue-select>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-vue-select :options="stateOptions" placeholder="State*"
                                      class="c-input c-input-select-b c-ajax-selected-white"
                                      v-model="model.state" @input="setDistrict(model.state)"
                                      :rules="{required:true}" name="State"></validated-vue-select>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-vue-select :options="districtOptions" placeholder="District*"
                                      class="c-input c-input-select-b c-ajax-selected-white" v-model="model.district"
                                      :rules="{required:true}" name="District"></validated-vue-select>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input type="number" placeholder="Pincode*" class="c-input c-input-number-modify-arrow-none"
                                 v-model="model.pin_code" name="Pincode"
                                 :rules="{required:true}"></validated-input>
            </div>

            <div class="box col-12 col-lg-4 col-md-6 font-inter-medium">
                <validated-checkbox v-model="model.real_estate_agent" label="Real Estate Agent" class="c-input" name="real estate agent"/>
            </div>

            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <btn text="Submit" type="submit" class="px-5 auth-button"></btn>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'ProprietorRegistration',

    props: {
        details: {
            type: Object
        }
    },

    data () {
        return {
            countryOptions: [],
            allStateOptions: [],
            stateOptions: [],
            allDistrictOptions: [],
            districtOptions: [],

            model: {
                organization_name: '',
                first_name: '',
                last_name: '',
                father_husband_name: '',
                mobile_number: '',
                address: '',
                country: '',
                state: '',
                district: '',
                pin_code: ''
            }
        };
    },

    mounted () {
        this.loadOptions();
        this.model = {
            organization_name: '',
            first_name: '',
            last_name: '',
            father_husband_name: '',
            mobile_number: this.details.mobile_number,
            address: '',
            country: '',
            state: '',
            district: '',
            pin_code: ''
        };
    },

    methods: {
        onSubmit () {
            this.$emit('on-submit', this.model);
        },

        loadOptions () {
            const that = this;

            axios.get(urls.website.formInitialization + '?type=Text').then(function (response) {
                const json = response.data;
                that.countryOptions = [...json.country.data];
                that.allStateOptions = [...json.state.data];
                that.allDistrictOptions = [...json.district.data];
                // that.stateOptions = [...json.state.data];
                // that.districtOptions = [...json.district.data];
            }).catch(function (exception) {
                console.log('exception occurred at options loading in reg form : ', exception);
            });
        },

        setState (country) {
            this.stateOptions = [];
            this.districtOptions = [];
            const filterValues = this.allStateOptions.filter(obj => obj.country === country);
            this.stateOptions = [...filterValues];
            this.model.state = '';
            this.model.district = '';
        },

        setDistrict (state) {
            this.districtOptions = [];
            const filterValues = this.allDistrictOptions.filter(obj => obj.state === state);
            this.districtOptions = [...filterValues];
            this.model.district = '';
        }
    }
};
</script>

<style scoped>
.box {
    color: darkgray;
}
</style>
