<template>
    <div>
        <s-form class="row" @submit="onSubmit">
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="Name of Organization*" class="c-input" :rules="{required:true}"
                                 v-model="model.organization_name" name="Name of Organization"></validated-input>
            </div>
            <!--            <div class="col-12 col-lg-4 col-md-6">-->
            <!--                <validated-input placeholder="Designation*" class="c-input" :rules="{required:true}"-->
            <!--                                 v-model="model.designation" name="Designation"></validated-input>-->
            <!--            </div>-->

            <div class="col-12">
                <div class="row" v-for="(partner, index) in partners" :key="index">
                    <div class="col-12 col-lg-4 col-md-6">
                        <validated-input :rules="{required: checkRequired(index)}" placeholder="Partner Name*"
                                         v-model="partners[index].partner_name"
                                         class="c-input" name="Partner Name"></validated-input>
                    </div>

                    <div class="col-12 col-lg-4 col-md-6">
                        <validated-input :rules="{required: checkRequired(index)}" placeholder="Designation*"
                                         v-model="partners[index].designation"
                                         class="c-input" name="Designation"></validated-input>
                    </div>

                    <div class="col-12 col-lg-4 col-md-6">
                        <div class="">
                            <btn v-if="partners.length === index + 1" type="button" icon="fa fa-plus" text=""
                                 class="mt-4 mr-2 auth-button" @click="addMorePartner"></btn>

                            <btn type="button" icon="fa fa-trash" color="danger" text="" v-if="index!==0" class="mt-4"
                                 @click="removePartner(index)"></btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="Authorized signatory*" class="c-input" :rules="{required:true}"
                                 v-model="model.authorized_signatory" name="Authorized signatory"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="LLP Registration Number*" class="c-input" :rules="{required:true}"
                                 v-model="model.llp_reg_nos" name="LLP Registration Number"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="GST" :rules="{required: false}" name="GST" class="c-input"
                                 v-model="model.gst_no"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input placeholder="PAN*" class="c-input" v-model="model.pan" name="Pan"
                                 :rules="{required: true, regex: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/}"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input type="number" placeholder="Phone Number*" :rules="{required:true, min:10, max:10}"
                                 class="c-input c-input-number-modify-arrow-none" name="Phone Number"
                                 v-model="model.mobile_number" :disabled="true"></validated-input>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-textarea placeholder="Address" class="c-textarea" :rules="{required:false}"
                                    v-model="model.address" name="Address"></validated-textarea>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-vue-select :options="countryOptions" placeholder="Country*" @input="setState(model.country)"
                                      class="c-input c-input-select-b c-ajax-selected-white" v-model="model.country"
                                      :rules="{required:true}" name="Country"></validated-vue-select>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-vue-select :options="stateOptions" placeholder="State*"
                                      class="c-input c-input-select-b c-ajax-selected-white"
                                      v-model="model.state" @input="setDistrict(model.state)"
                                      :rules="{required:true}" name="State"></validated-vue-select>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-vue-select :options="districtOptions" placeholder="District*"
                                      class="c-input c-input-select-b c-ajax-selected-white" v-model="model.district"
                                      :rules="{required:true}" name="District"></validated-vue-select>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <validated-input type="number" placeholder="Pincode*" class="c-input c-input-number-modify-arrow-none"
                                 v-model="model.pin_code" name="Pincode" :rules="{required:true}"></validated-input>
            </div>

            <div class="box col-12 col-lg-4 col-md-6 font-inter-medium">
                <validated-checkbox v-model="model.real_estate_agent" label="Real Estate Agent" class="c-input" name="real estate agent"/>
            </div>

            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <btn text="Submit" type="submit" class="px-5 auth-button"></btn>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'LlpRegistration',

    props: {
        details: {
            type: Object
        }
    },

    data () {
        return {
            countryOptions: [],
            allStateOptions: [],
            stateOptions: [],
            allDistrictOptions: [],
            districtOptions: [],

            partners: [
                {
                    partner_name: '',
                    designation: ''
                }
            ],
            model: {
                organization_name: '',
                designation: '',
                partners: '',
                authorized_signatory: '',
                llp_reg_nos: '',
                gst_no: '',
                pan: '',
                mobile_number: '',
                address: '',
                country: '',
                state: '',
                district: '',
                pin_code: ''

            }
        };
    },

    mounted () {
        this.loadOptions();
        this.model = {
            organization_name: '',
            designation: '',
            partners: '',
            authorized_signatory: '',
            llp_reg_nos: '',
            gst_no: '',
            pan: '',
            mobile_number: this.details.mobile_number,
            address: '',
            country: '',
            state: '',
            district: '',
            pin_code: ''
        };
    },

    methods: {
        loadOptions () {
            const that = this;

            axios.get(urls.website.formInitialization + '?type=Text').then(function (response) {
                const json = response.data;
                that.countryOptions = [...json.country.data];
                that.allStateOptions = [...json.state.data];
                that.allDistrictOptions = [...json.district.data];
                // that.stateOptions = [...json.state.data];
                // that.districtOptions = [...json.district.data];
            }).catch(function (exception) {
                console.log('exception occurred at options loading in reg form : ', exception);
            });
        },

        setState (country) {
            this.stateOptions = [];
            this.districtOptions = [];
            const filterValues = this.allStateOptions.filter(obj => obj.country === country);
            this.stateOptions = [...filterValues];
            this.model.state = '';
            this.model.district = '';
        },

        setDistrict (state) {
            this.districtOptions = [];
            const filterValues = this.allDistrictOptions.filter(obj => obj.state === state);
            this.districtOptions = [...filterValues];
            this.model.district = '';
        },

        checkRequired (index) {
            return index === 0;
        },

        addMorePartner () {
            this.partners.push({
                partner_name: '',
                designation: ''
            });
        },

        removePartner (index) {
            this.partners.splice(index, 1);
        },

        cleanData () {
            for (let i = 0; i < this.partners.length; i++) {
                if (this.partners[i].partner_name === '') {
                    delete this.partners[i];
                }
            }
        },

        onSubmit () {
            this.cleanData();
            this.model.partners = JSON.stringify(this.partners);
            this.$emit('on-submit', this.model);
        }
    }
};
</script>

<style scoped>
.box {
    color: darkgray;
}
</style>
