<template>
    <div>
        <s-form class="row" @submit="onSubmit" ref="formValidator">

            <div class="col-12 col-lg-4 col-md-6">
                <validated-input label="Email*" type="email" class="c-input"
                                 v-model="model.email_id" name="Email Id"></validated-input>

                <validated-input label="Password*" name="Password" type="password" class="c-input"
                                 v-model="model.password"></validated-input>
                <p class="mt-3 mb-3 text-3 fs--1">Password must contain at least 8 characters, including upper &
                    lower
                    case letters, numbers and
                    special characters.</p>
                <p v-if="showError" class="text-danger">{{ errorMessage }}</p>
            </div>
            <div class="col-12">
                <div class="fl-x">
                    <btn text="Back" type="button" class="mr-2 px-5" @click="$emit('goBack')"/>
                    <btn text="Submit" class="px-5" :loading="loading" loading-text="Submitting..."
                         type="submit"></btn>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'SetEmailAndPassword',

    props: {
        type: { type: String },

        data: { type: Object }
    },

    data () {
        return {
            errorMessage: '',
            showError: false,
            loading: false,

            model: {
                email_id: '',
                password: ''
            }
        };
    },

    methods: {
        async onSubmit () {
            this.loading = true;
            if (this.type === 'individual') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.individual, model);
                console.log('individual-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'group-ind') {
                const first = this.data.shift();

                const model = {
                    ...this.model,
                    ...first,
                    other_individuals: JSON.stringify([...this.data])
                };

                const response = await axios.form(urls.register.groupIndividual, model);
                console.log('groupIndividual-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'proprietor') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.proprietor, model);
                console.log('proprietor-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'partnership') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.partnership, model);
                console.log('partnership-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'llp') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.llp, model);
                console.log('llp-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'private-ltd') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.private, model);
                console.log('private-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'society') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.society, model);
                console.log('society-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
