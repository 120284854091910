<template>
    <div class="place-holder-white input-placeholder-white">
        <transition name="fade" mode="out-in">

            <s-form class="row" v-if="!ShowOTPPage" key="'get-otp'" @submit="getOtp">
                <div class="col-12 col-lg-4 col-md-6">

                    <validated-input @input="showErrorMessage=false" label="Enter Mobile Number*"
                                     :rules="{required:true, min:10, max:10}" min="10" max="10"
                                     class="c-input input-color-white label-bold c-input-number-modify-arrow-none"
                                     v-model="model.mobile_number" type="number"
                                     name="Mobile Number">
                        <template #prepend>
                            <div class="text-white fl-x pr-2 pos-r"><span class="mr-1">+91</span><span class="d-inline"><i
                                class="fa fa-caret-down"></i></span>
                                <span class="line-right-rel ml-2"></span>
                            </div>
                        </template>
                    </validated-input>

                    <p class="mb-0 text-danger" v-if="showErrorMessage">{{ errorMessage }}</p>

                    <btn block text="Get OTP" :loading="sendingOTP" loading-text="Sending..."
                         class="mt-4 border-r--2 auth-button py-3"></btn>
                </div>
            </s-form>

            <s-form class="row" v-else key="'verify-number'" @submit="verifyNumber">

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input type="password" label="Enter OTP*" name="OTP"
                                     class="c-input" v-model="verify.otp"
                                     :rules="{required: true}"/>

                    <p class="mb-0 text-danger" v-if="showErrorMessage">{{ errorMessage }}</p>
                    <p v-if="isInvalidOtp" class="text-danger">Invalid OTP</p>

                    <div class="fl-x fl-j-e">
                        <span @click="resendOtp" class="font-inter-extra-light text-white fs--1 cursor-pointer">
                            Not yet received ? <span class=""><u>Resend</u></span>
                        </span>
                    </div>
                    <p v-if="resendOtpMessage!==''" class="font-inter-extra-light text-white fs--1 mt-4">
                        {{ resendOtpMessage }}</p>

                    <div class="fl-te-c">
                        <btn block type="button" text="Back" class="mt-4 auth-button col-3 border-r--2 py-3"
                             @click="ShowOTPPage = false"></btn>
                        <btn block text="Verify Number" class="mt-4 col-6  border-r--2 auth-button py-3"></btn>
                    </div>
                </div>

            </s-form>

        </transition>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'RegisterMobileAuthentication',

    data () {
        return {
            resendLoading: false,
            isInvalidOtp: false,
            resendOtpMessage: '',
            sendingOTP: false,
            verifyOTP: false,
            ShowOTPPage: false,
            errorMessage: '',
            showErrorMessage: false,

            model: {
                mobile_number: ''
            },

            verify: {
                otp: ''
            }
        };
    },

    methods: {
        async resendOtp () {
            this.isInvalidOtp = false;
            this.resendLoading = true;
            const response = await axios.get(urls.auth.resendLoginOtp, {
                params:
                    { mobile_number: this.model.mobile_number }
            });
            const json = response.data;
            if (json.error === false) {
                this.resendOtpMessage = 'OTP has been successfully send to ' + this.model.mobile_number + '.';
            } else {
                this.resendOtpMessage = 'Could not resend OTP, Please try again.';
            }
            this.resendLoading = false;
        },
        async getOtp () {
            this.sendingOTP = true;
            const response = await axios.form(urls.auth.OTPSendToMobile, this.model);
            if (response.data.success) {
                this.ShowOTPPage = true;
            } else {
                this.showErrorMessage = true;
                this.errorMessage = response.data.message;
            }
            this.sendingOTP = false;
        },

        async verifyNumber () {
            this.showErrorMessage = false;
            this.verifyOTP = true;
            const verify = {
                ...this.model, ...this.verify
            };
            const response = await axios.form(urls.auth.OTPVerify, verify);
            if (response.data.success) {
                this.$emit('verify-success', verify);
            } else {
                this.showErrorMessage = true;
                this.errorMessage = response.data.message;
            }
            this.verifyOTP = false;
        }
    }
};
</script>

<style scoped>

</style>
