<template>
    <div>
        <s-form @submit="setSubmit">
            <div v-for="(model, index) in forms" v-bind:key="index" class="row py-3 c-line-top">

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="First Name*" class="c-input" v-model="model.first_name"
                                     name="First Name"
                                     @blur="valueUpdated('first_name', index)" :rules="{required: true}"/>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Last Name" class="c-input" v-model="model.last_name"
                                     name="Last Name"
                                     @blur="valueUpdated('last_name', index)"
                                     :rules="{required: false}"></validated-input>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Father/Husband Name" class="c-textarea c-input"
                                     name="Father/Husband Name"
                                     v-model="model.father_husband_name" :rules="{required: false}"
                                     @blur="valueUpdated('father_husband_name', index)"></validated-input>
                </div>

                <div class="col-12 col-lg-12 col-md-12">
                    <validated-textarea placeholder="Address" class="c-textarea" v-model="model.address"
                                        @blur="valueUpdated('address', index)" name="Address"
                                        :rules="{required: false}"></validated-textarea>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input type="number" placeholder="Phone Number*" name="Phone Number"
                                     class="c-input c-input-number-modify-arrow-none"
                                     v-model="model.mobile_number" :rules="{required: true, min:10, max:10}"
                                     @blur="valueUpdated('mobile_number', index)"></validated-input>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="PAN*" class="c-input c-input-number-modify-arrow-none"
                                     v-model="model.pan" @blur="valueUpdated('pan', index)" name="Pan"
                                     :rules="{required:true, regex: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/}"/>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-vue-select :options="countryOptions" placeholder="Country*"
                                          @input="setState(model.country)"
                                          class="c-input c-input-select-b c-ajax-selected-white" v-model="model.country"
                                          :rules="{required:true}" name="Country"
                                          @blur="valueUpdated('country', index)"></validated-vue-select>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-vue-select :options="stateOptions" placeholder="State*"
                                          class="c-input c-input-select-b c-ajax-selected-white"
                                          v-model="model.state" @input="setDistrict(model.state)"
                                          @blur="valueUpdated('state', index)" name="State"
                                          :rules="{required:true}"></validated-vue-select>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-vue-select :options="districtOptions" placeholder="District*"
                                          @blur="valueUpdated('district', index)"
                                          class="c-input c-input-select-b c-ajax-selected-white"
                                          v-model="model.district" name="District"
                                          :rules="{required:true}"></validated-vue-select>
                </div>

                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input type="number" placeholder="Pincode*"
                                     class="c-input c-input-number-modify-arrow-none"
                                     v-model="model.pin_code" :rules="{required: true}" name="Pincode"
                                     @blur="valueUpdated('pin_code', index)"></validated-input>
                </div>

                <div class="box col-12 col-lg-4 col-md-6 font-inter-medium">
                    <validated-checkbox v-model="model.real_estate_agent" label="Real Estate Agent" class="c-input" name="real estate agent"/>
                </div>

                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <btn icon="fa fa-plus" class="mr-2 auth-button" @click="addRow" type="button" size="sm"></btn>
                        <btn type="button" v-if="index !== 0" class="auth-button" icon="fa fa-trash" color="danger"
                             size="sm"
                             @click="deleteRow(index)"/>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-12 mt-3">
                    <div class="fl-x fl-j-e">
                        <btn text="Submit" class="px-5 auth-button"></btn>
                    </div>
                </div>
            </div>
        </s-form>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'GroupOfIndividualsRegistration',

    props: {
        data: {
            type: Array
        },
        details: {
            type: Object
        }
    },

    data () {
        return {
            countryOptions: [],
            allStateOptions: [],
            stateOptions: [],
            allDistrictOptions: [],
            districtOptions: [],
            forms: null
        };
    },

    mounted () {
        this.loadOptions();

        if (this.data.length === 1) {
            const data = this.data[0];
            this.forms = [{
                first_name: data.first_name,
                last_name: data.last_name,
                father_husband_name: data.father_husband_name,
                mobile_number: this.details.mobile_number,
                address: data.address,
                country: data.country,
                state: data.state,
                district: data.district,
                pin_code: data.pin_code,
                pan: data.pan
            }];
        }

        if (this.data.length === 0) {
            this.forms = [{
                first_name: '',
                last_name: '',
                father_husband_name: '',
                mobile_number: '',
                address: '',
                country: '',
                state: '',
                district: '',
                pin_code: '',
                pan: ''
            }];
        }

        if (this.data.length > 1) {
            const data = this.data.shift();
            this.forms = [{
                first_name: data.first_name,
                last_name: data.last_name,
                father_husband_name: data.father_husband_name,
                mobile_number: this.details.mobile_number,
                address: data.address,
                country: data.country,
                state: data.state,
                district: data.district,
                pin_code: data.pin_code,
                pan: data.pan
            }];
            this.forms = [...this.forms, ...this.data];
        }
    },

    methods: {
        loadOptions () {
            const that = this;

            axios.get(urls.website.formInitialization + '?type=Text').then(function (response) {
                const json = response.data;
                that.countryOptions = [...json.country.data];
                that.allStateOptions = [...json.state.data];
                that.allDistrictOptions = [...json.district.data];
                // that.stateOptions = [...json.state.data];
                // that.districtOptions = [...json.district.data];
            }).catch(function (exception) {
                console.log('exception occurred at options loading in reg form : ', exception);
            });
        },

        setState (country) {
            this.stateOptions = [];
            this.districtOptions = [];
            const filterValues = this.allStateOptions.filter(obj => obj.country === country);
            this.stateOptions = [...filterValues];
            // this.model.state = '';
            // this.model.district = '';
        },

        setDistrict (state) {
            this.districtOptions = [];
            const filterValues = this.allDistrictOptions.filter(obj => obj.state === state);
            this.districtOptions = [...filterValues];
            // this.model.district = '';
        },

        onSubmit () {
            this.$emit('on-submit');
        },

        valueUpdated (key, index) {
            this.$emit('onUpdate', key, this.forms[index][key], index);
        },

        addRow () {
            this.$emit('addRow');
            this.forms.push({
                first_name: '',
                last_name: '',
                father_husband_name: '',
                mobile_number: '',
                address: '',
                country: '',
                state: '',
                district: '',
                pin_code: '',
                pan: ''
            });
        },

        deleteRow (index) {
            this.$emit('deleteRow', index);
            this.forms.splice(index, 1);
        },

        setSubmit () {
            this.$emit('submitSuccess', this.forms);
        }
    }
};
</script>

<style scoped lang="scss">
.box {
    color: darkgray;
}

.c-line-top {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 1%;
        top: 0;
        height: 0.5px;
        width: 100%;
        margin-bottom: 1rem;
        background-color: rgba(128, 128, 128, 0.4);
    }
}
</style>
